// Миддлвар для проверки валидности и обновления JWT токена
export default defineNuxtRouteMiddleware(async (to) => {
  const jwtStore = useJwtStore();
  const authPaths = ['/', '/oauth/sber_id', '/oauth/sber_id/authorize_url', '/oauth/sber_id/sso'];
  const error = useError();
  const authRedirectPath = useAuthRedirectPath();

  /**
   * Сохраняет текущий путь в куки.
   */
  const savePathToCookie = () => {
    if (process.server) {
      authRedirectPath.setPath(to.fullPath);
    }
  };

  // если выполняется на стороне сервера - пробуем достать значения токенов из куки запроса
  if (process.server) {
    jwtStore.initializeByCookie();
  }

  // Если мы на странице ошибки - пропускаем
  if (error.value) {
    return;
  }

  // если рефреш-токен присутствует
  if (jwtStore.isExists()) {
    // если токен протух - пробуем обновить
    if (jwtStore.isExpired()) {
      console.log('JWT токен просрочен!');
      const refreshed = await jwtStore.refresh();

      if (refreshed) {
        console.log('JWT токен успешно обновлен!');

        // если пытаются перейти на страницы авторизации - редиректим в каталог
        if (authPaths.includes(to.path)) {
          return navigateTo('/catalog');
        }
      } else {
        console.log('Не удалось обновить JWT токен!');
        jwtStore.clear();

        // если пытаются зайти на защищенную страницу - редиректим на страницу авторизации
        if (!authPaths.includes(to.path)) {
          savePathToCookie();
          return navigateTo('/');
        }
      }
    } else {
      // если пытаются перейти на страницы авторизации - редиректим в каталог
      if (authPaths.includes(to.path)) {
        return navigateTo('/catalog');
      }
    }
  } else {
    console.log('JWT токен отсутствует!');

    // если пытаются зайти на защищенную страницу - редиректим на страницу авторизации
    if (!authPaths.includes(to.path)) {
      savePathToCookie();
      return navigateTo('/');
    }
  }
});
