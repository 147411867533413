import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as _91id_93dJSlMJUv8uMeta } from "/app/pages/assets/[id].vue?macro=true";
import { default as catalogmbyzFSoe6qMeta } from "/app/pages/catalog.vue?macro=true";
import { default as confirmiabBgwNTSOMeta } from "/app/pages/confirm.vue?macro=true";
import { default as favoritesYMtsvzhBvNMeta } from "/app/pages/favorites.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91category_93mcFmpTMOftMeta } from "/app/pages/kinds/[category].vue?macro=true";
import { default as authorize_url5JV3z7FvizMeta } from "/app/pages/oauth/sber_id/authorize_url.vue?macro=true";
import { default as indexosT15giW2RMeta } from "/app/pages/oauth/sber_id/index.vue?macro=true";
import { default as ssobAFUkhLH42Meta } from "/app/pages/oauth/sber_id/sso.vue?macro=true";
import { default as searchfcnTL5fl5XMeta } from "/app/pages/search.vue?macro=true";
import { default as testpage98R9ZDx9mOMeta } from "/app/pages/testpage.vue?macro=true";
export default [
  {
    name: indexi8nCATZISJMeta?.name ?? "admin",
    path: indexi8nCATZISJMeta?.path ?? "/admin",
    meta: indexi8nCATZISJMeta || {},
    alias: indexi8nCATZISJMeta?.alias || [],
    redirect: indexi8nCATZISJMeta?.redirect || undefined,
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93dJSlMJUv8uMeta?.name ?? "assets-id",
    path: _91id_93dJSlMJUv8uMeta?.path ?? "/assets/:id()",
    meta: _91id_93dJSlMJUv8uMeta || {},
    alias: _91id_93dJSlMJUv8uMeta?.alias || [],
    redirect: _91id_93dJSlMJUv8uMeta?.redirect || undefined,
    component: () => import("/app/pages/assets/[id].vue").then(m => m.default || m)
  },
  {
    name: catalogmbyzFSoe6qMeta?.name ?? "catalog",
    path: catalogmbyzFSoe6qMeta?.path ?? "/catalog",
    meta: catalogmbyzFSoe6qMeta || {},
    alias: catalogmbyzFSoe6qMeta?.alias || [],
    redirect: catalogmbyzFSoe6qMeta?.redirect || undefined,
    component: () => import("/app/pages/catalog.vue").then(m => m.default || m)
  },
  {
    name: confirmiabBgwNTSOMeta?.name ?? "confirm",
    path: confirmiabBgwNTSOMeta?.path ?? "/confirm",
    meta: confirmiabBgwNTSOMeta || {},
    alias: confirmiabBgwNTSOMeta?.alias || [],
    redirect: confirmiabBgwNTSOMeta?.redirect || undefined,
    component: () => import("/app/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: favoritesYMtsvzhBvNMeta?.name ?? "favorites",
    path: favoritesYMtsvzhBvNMeta?.path ?? "/favorites",
    meta: favoritesYMtsvzhBvNMeta || {},
    alias: favoritesYMtsvzhBvNMeta?.alias || [],
    redirect: favoritesYMtsvzhBvNMeta?.redirect || undefined,
    component: () => import("/app/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91category_93mcFmpTMOftMeta?.name ?? "kinds-category",
    path: _91category_93mcFmpTMOftMeta?.path ?? "/kinds/:category()",
    meta: _91category_93mcFmpTMOftMeta || {},
    alias: _91category_93mcFmpTMOftMeta?.alias || [],
    redirect: _91category_93mcFmpTMOftMeta?.redirect || undefined,
    component: () => import("/app/pages/kinds/[category].vue").then(m => m.default || m)
  },
  {
    name: authorize_url5JV3z7FvizMeta?.name ?? "oauth-sber_id-authorize_url",
    path: authorize_url5JV3z7FvizMeta?.path ?? "/oauth/sber_id/authorize_url",
    meta: authorize_url5JV3z7FvizMeta || {},
    alias: authorize_url5JV3z7FvizMeta?.alias || [],
    redirect: authorize_url5JV3z7FvizMeta?.redirect || undefined,
    component: () => import("/app/pages/oauth/sber_id/authorize_url.vue").then(m => m.default || m)
  },
  {
    name: indexosT15giW2RMeta?.name ?? "oauth-sber_id",
    path: indexosT15giW2RMeta?.path ?? "/oauth/sber_id",
    meta: indexosT15giW2RMeta || {},
    alias: indexosT15giW2RMeta?.alias || [],
    redirect: indexosT15giW2RMeta?.redirect || undefined,
    component: () => import("/app/pages/oauth/sber_id/index.vue").then(m => m.default || m)
  },
  {
    name: ssobAFUkhLH42Meta?.name ?? "oauth-sber_id-sso",
    path: ssobAFUkhLH42Meta?.path ?? "/oauth/sber_id/sso",
    meta: ssobAFUkhLH42Meta || {},
    alias: ssobAFUkhLH42Meta?.alias || [],
    redirect: ssobAFUkhLH42Meta?.redirect || undefined,
    component: () => import("/app/pages/oauth/sber_id/sso.vue").then(m => m.default || m)
  },
  {
    name: searchfcnTL5fl5XMeta?.name ?? "search",
    path: searchfcnTL5fl5XMeta?.path ?? "/search",
    meta: searchfcnTL5fl5XMeta || {},
    alias: searchfcnTL5fl5XMeta?.alias || [],
    redirect: searchfcnTL5fl5XMeta?.redirect || undefined,
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: testpage98R9ZDx9mOMeta?.name ?? "testpage",
    path: testpage98R9ZDx9mOMeta?.path ?? "/testpage",
    meta: testpage98R9ZDx9mOMeta || {},
    alias: testpage98R9ZDx9mOMeta?.alias || [],
    redirect: testpage98R9ZDx9mOMeta?.redirect || undefined,
    component: () => import("/app/pages/testpage.vue").then(m => m.default || m)
  }
]